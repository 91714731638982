<template>
  <div class="login">
    <!-- 头部，标题介绍 -->
    <div class="headers">
      <div class="logoMain"></div>
      <div class="header-tittle">科达光电会议签到管理系统</div>
    </div>
    <!-- 主体 -->
    <div class="login-body">
      <el-form  :model="loginForm">
        <el-form-item>
          <el-input v-model="loginForm.account"
                    prefix-icon="el-icon-s-custom" placeholder="请输入您的用户名"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="loginForm.password"
                    type="password"
                    prefix-icon="el-icon-lock" placeholder="请输入您的密码"></el-input>
        </el-form-item>
      </el-form>
      <div class="login-submit" @click="handleLogin">登录</div>
    </div>
    <!-- 底部备案 -->
    <div class="footer">
      <span class="text">
        科达光电有限公司版权所有蜀ICP备2022018207号-1
      </span>
    </div>
  </div>
</template>

<script>
import logins from '../../api/modules/login'
import common from "../../api/modules/common";
export default {
  name: "loginIndex",
  data() {
    return {
      loginForm:{
        account: '',
        password:'',
        code:''
      }
    }
  },
  methods:{
    handleLogin(){
      logins.login({account:this.loginForm.account, password:this.loginForm.password}).then(res =>{
        let data = res.data
        if(!data.staffRole || data.staffRole.length <=0){
          this.$message({
            message: '用户未授权，请联系管理人员授权',
            type: 'error'
          });
          return
        }
        // 存储信息入store中
        this.$store.dispatch('common/setLoginUserInfo', data)
        // 获取用户信息
        common.selectStaffDetail({staffId:data.staffId}).then(res =>{
          this.$message({
            message: '登录成功',
            type: 'success'
          });
          this.$store.dispatch('common/setUserInfo', res.data)
          this.$router.push('/view/basic/company')
        })
      })
    }
  },
  mounted() {
    // 实现按下回车键后登录
    document.onkeydown = () => {
      let key = window.event.keyCode
      if (key === 13) {
        this.handleLogin()
      }
    }
  },
  destroyed() {
    document.onkeydown = null
  }
};
</script>

// 样式，scoped属性，当前组件页生效
<style lang="less" scoped>
.login{
  height: 100%;
  background: url("../../assets/images/login_Img/back.jpg") no-repeat;
  background-size: 100% 100% ;
  position: relative;
  .headers{
    padding-top: 60px;
    .logoMain{
      width: 80px;
      height: 80px;
      background: url('../../assets/images/login_Img/logo.png') no-repeat;
      background-size: contain;
      margin: 20px auto;
    }
    .header-tittle{
      -webkit-box-reflect:below -10px linear-gradient(transparent, rgba(0, 0, 0, .08));
      text-align: center;
      letter-spacing: .060125rem;
      color: rgb(251, 251, 251);
      font-size: 48px;
      font-family: 'HuXiaoBoNanShenTi-2';
      font-weight: 400;
      text-shadow: 0px 8px 16px rgba(0,0,0,0.3);
    }
  }
  .login-body{
    width: 445px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-36%);
    .login-submit{
      color: #ffffff;
      background: url("../../assets/images/login_Img/btn_back.png") no-repeat;
      background-size: cover;
      height: 55px;
      text-align: center;
      line-height: 55px;
      cursor: pointer;
      margin-top: 50px;
    }
  }
  .footer{
    position: absolute;
    bottom: 100px;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 13px;
    color: #ffffff;
  }
}

</style>
