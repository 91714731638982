import api from '../httpServer'
import config from '../config'

const common ={
    /*
    * 管理人员
    * */
    // 删除用户
    delStaff(data) {
        return api.post(`${config.headerUrl}staff/delStaff`,data)
    },
    //修改密码
    editPwd(data) {
        return api.post(`${config.headerUrl}staff/editPwd`,data)
    },
    // 新增用户
    newStaff(data) {
        return api.post(`${config.headerUrl}staff/newStaff`,data)
    },
    // 重置密码
    resetPwd(data) {
        return api.post(`${config.headerUrl}staff/resetPwd`,data)
    },
    // 修改用户
    updateStaff(data) {
        return api.post(`${config.headerUrl}staff/updateStaff`,data)
    },
    // 分页查询管理人员
    selectStaff(data) {
        return api.get(`${config.headerUrl}staff/selectStaff`,data)
    },
    // 查询管理人员详情
    selectStaffDetail(data) {
        return api.get(`${config.headerUrl}staff/selectStaffDetail`,data)
    },
    // 查询管理人员选择列表
    selectStaffList(data) {
        return api.get(`${config.headerUrl}staff/selectStaffList`,data)
    },
    /*
    * 图片上传
    * */
    fileUpload(data) {
        return api.post(`${config.headerUrl}File/upload`,data)
    },
    /*
    * 设备类型
    * */
    getEquipmenttype(data) {
        return api.get(`${config.headerUrl}device/GetEquipmentTypeList`,data)
    },
    // 获取设备类型 （懒加载） equipTypeId = 0 首层
    getEquipmentList(data) {
        return api.get(`${config.headerUrl}equipType/getTree`,data)
    },
    /*
    * 准操作项目
    * */
    // 获取列表
    getEquipmentTypeList(data) {
        return api.get(`${config.headerUrl}device/GetEquipmentTypeList`,data)
    },
    // 获取详情
    getEquipmentTypeDetail(data) {
        return api.get(`${config.headerUrl}operatingItem/getDetail`,data)
    },
    //准操作项目树
    getEquipmentTree(data){
        return api.get(`${config.headerUrl}operatingItem/getTree`,data)
    }
}


export default common